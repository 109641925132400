/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import showdown from 'showdown';
import withRoleRequired from '../../../lib/withRoleRequired';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import SWOTPage from '../../../components/swot/swotPage';
import {
  Scenario,
  SWOT,
  SWOTQueryData,
  Analysis,
  AnalysisQueryData,
} from '../../../types/Global';
import IndexCard from '../../../components/IndexCard';
import { mapEdgesToNodes } from '../../../lib/helpers';

interface Props {
  data: {
    scenariosCsv: Scenario;
    allAnalysesCsv: AnalysisQueryData[];
    allSwotCsv: SWOTQueryData;
  };
}

const ScenarioSWOTIndexPageTemplate: React.FunctionComponent<Props> = ({
  data,
}) => {
  let analyses: Analysis[] = mapEdgesToNodes(data.allAnalysesCsv);
  const swots: SWOT[] = mapEdgesToNodes(data.allSwotCsv);
  const { title, slug, swotIntroduction } = data.scenariosCsv;
  const converter = new showdown.Converter();

  // Only keep the analyses that are relevant for this scenario
  analyses = analyses.filter((analysis) =>
    swots.some((thisSwot) => thisSwot.analysis === analysis.slug)
  );

  if (analyses.length === 1) {
    return (
      <SWOTPage
        scenario={data.scenariosCsv}
        analysis={analyses[0]}
        swots={swots}
        indexInBreadcrumbs={false}
      />
    );
  }

  return (
    <Layout
      pageHeader="SWOT Analyses"
      breadcrumbs={[
        { label: 'Scenarios', link: '/scenarios' },
        { label: title, link: `/scenarios/${slug}` },
      ]}
      moreInfo="scenarios/swot"
    >
      <SEO title={`${title} SWOT Analyses`} />

      <div className="max-w-6xl px-4 py-4 mx-auto md:px-8 md:py-4">
        <div className="mb-8">
          <div
            dangerouslySetInnerHTML={{
              __html: `${converter.makeHtml(swotIntroduction)}`,
            }}
          />
        </div>
        <section>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {analyses &&
              analyses.map((analysis) => {
                const swotTitle = swots.find(swot => swot.analysis === analysis.slug)?.swotTitle;
                return (
                  <IndexCard
                    key={analysis.slug}
                    imageSource={analysis.image}
                    link={`/scenarios/${slug}/swot/${analysis.slug}`}
                    title={swotTitle || ''}
                    description={analysis.description}
                  />

                )
              })}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($id: String, $slug: String) {
    scenariosCsv(id: { eq: $id }) {
      title
      slug
      narrativeImage
      narrativeTitle
      dataImage
      dataTitle
      personasImage
      personasTitle
      swotImage
      swotTitle
      swotIntroduction
    }
    allAnalysesCsv {
      edges {
        node {
          slug
          image
          description
        }
      }
    }
    allSwotCsv(filter: { scenario: { eq: $slug } }) {
      edges {
        node {
          scenario
          analysis
          swotTitle
          category
          title
          description
        }
      }
    }
  }
`;

export default withRoleRequired(ScenarioSWOTIndexPageTemplate);
