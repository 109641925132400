import React from 'react';
import { SWOT } from '../../types/Global';
import SWOTItem from './swotItem';

interface SWOTSectionProps {
  titleBG: string;
  cardBG: string;
  title: string;
  factors: SWOT[];
  openItem: string | null;
  setOpenItem: (openItem: string | null) => void;
}

const SWOTSection: React.FunctionComponent<SWOTSectionProps> = ({ titleBG, cardBG, title, factors, openItem, setOpenItem }) => (
  <div className={`overflow-visible ${cardBG} overflow-hidden flex-1`}>
    <div className={`${titleBG} px-2 py-2 text-center text-white text-2xl font-bold`}>
      {title}
    </div>
    <div className="px-3 py-1">
      {factors && factors.map((factor) => (
        <SWOTItem openItem={openItem} setOpenItem={setOpenItem} key={factor.title} sectionTitle={title} title={factor.title} description={factor.description} />
      ))}
    </div>
  </div>
);

export default SWOTSection;