import React, { useState } from 'react';
import Layout from '../layout';
import SEO from '../seo';
import { Scenario, Analysis, SWOT } from '../../types/Global';
import SWOTSection from './swotSection';
import OtherLink from './otherLink';

interface Props {
  scenario: Scenario;
  analysis: Analysis;
  swots: SWOT[];
  indexInBreadcrumbs: boolean;
}

const SWOTPage: React.FunctionComponent<Props> = ({
  scenario,
  analysis,
  swots,
  indexInBreadcrumbs,
}) => {
  const [openItem, setOpenItem] = useState<string | null>(null);
  const {
    slug,
    narrativeImage,
    narrativeTitle,
    dataImage,
    dataTitle,
    personasImage,
    personasTitle,
  } = scenario;
  const breadcrumbs = [
    { label: 'Scenarios', link: '/scenarios' },
    { label: scenario.title, link: `/scenarios/${scenario.slug}` },
  ];
  if (indexInBreadcrumbs) {
    breadcrumbs.push({
      label: 'SWOT Analyses',
      link: `/scenarios/${slug}/swot`,
    });
  }

  return (
    <Layout
      pageHeader={swots[0].swotTitle}
      breadcrumbs={breadcrumbs}
      moreInfo="scenarios/swot"
    >
      <SEO title={`${scenario.title} ${analysis.title}`} />
      <h2 className="mb-8 text-center text-xl font-bold">
        Click on the individual strengths, weaknesses, opportunities and threats
        to see more details.
      </h2>
      <section className="max-w-4xl px-4 py-4 mx-auto md:px-8">
        <div className="grid grid-cols-5 sm:grid-cols-11 gap-2">
          <div className="hidden sm:block">&nbsp;</div>
          <div className="hidden sm:block col-span-5 bg-dstl-light-gray px-2 py-2 text-center text-2xl font-normal">
            Helpful
          </div>
          <div className="hidden sm:block col-span-5 bg-dstl-light-gray px-2 py-2 text-center text-2xl font-normal">
            Harmful
          </div>
          <div className="hidden sm:grid bg-dstl-light-gray text-center grid grid-cols-1 place-content-center">
            <div className="transform origin-bottom-center -rotate-90 text-2xl">
              Internal
            </div>
          </div>
          <div className="col-span-5 flex flex-col">
            <SWOTSection
              openItem={openItem}
              setOpenItem={setOpenItem}
              titleBG="bg-dstl-purple"
              cardBG="bg-dstl-light-gray"
              title="Strengths"
              factors={swots.filter((swot) => swot.category === 'strength')}
            />
          </div>
          <div className="col-span-5 flex flex-col">
            <SWOTSection
              openItem={openItem}
              setOpenItem={setOpenItem}
              titleBG="bg-dstl-crimson"
              cardBG="bg-dstl-light-gray"
              title="Weaknesses"
              factors={swots.filter((swot) => swot.category === 'weakness')}
            />
          </div>
          <div className="hidden sm:grid bg-dstl-light-gray text-center grid grid-cols-1 place-content-center">
            <div className="transform origin-bottom-center -rotate-90 text-2xl ">
              External
            </div>
          </div>
          <div className="col-span-5 flex flex-col">
            <SWOTSection
              openItem={openItem}
              setOpenItem={setOpenItem}
              titleBG="bg-dstl-dark-gray"
              cardBG="bg-dstl-light-gray"
              title="Opportunities"
              factors={swots.filter((swot) => swot.category === 'opportunity')}
            />
          </div>
          <div className="col-span-5 flex flex-col">
            <SWOTSection
              openItem={openItem}
              setOpenItem={setOpenItem}
              titleBG="bg-dstl-medium-purple"
              cardBG="bg-dstl-light-gray"
              title="Threats"
              factors={swots.filter((swot) => swot.category === 'threat')}
            />
          </div>
        </div>
      </section>
      <section className="max-w-4xl px-4 py-4 mx-auto md:px-8">
        <div className="mb-8 text-xl font-bold">
          You may also be interested in :
        </div>
        <OtherLink
          imageSource={narrativeImage}
          link={`/scenarios/${slug}/narrative`}
          title="Scenario Narrative"
          description={narrativeTitle}
        />
        <OtherLink
          imageSource={dataImage}
          link={`/scenarios/${slug}/data`}
          title="Scenario In Data"
          description={dataTitle}
        />
        <OtherLink
          imageSource={personasImage}
          link={`/scenarios/${slug}/personas`}
          title="Scenario Personas"
          description={personasTitle}
        />
      </section>
    </Layout>
  );
};

export default SWOTPage;
