/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

interface SWOTItemProps {
  sectionTitle: string;
  title: string;
  description: string;
  openItem: string | null;
  setOpenItem: (openItem: string | null) => void;
}

const SWOTItem: React.FunctionComponent<SWOTItemProps> = ({ sectionTitle, title, description, openItem, setOpenItem }) => {
  const myID = `${sectionTitle}#${title}`;
  const open = (openItem === myID);
  return (
    <div
      onClick={() => setOpenItem(open ? null : myID)}
      className="flex relative w-full bg-white border border-gray-300 hover:bg-dstl-light-crimson cursor-pointer rounded-lg my-1 py-1 px-2 font-bold"
    >
      <div className="flex-1">{title}</div>
      {!open && <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 inline flex-none" aria-hidden="true" />}
      {open &&
        <div className="absolute top-0 left-0 z-900 w-full">
          <div
            onClick={() => setOpenItem(open ? null : myID)}
            className="relative w-full bg-white border border-gray-300 shadow-md hover:bg-dstl-light-crimson cursor-pointer rounded-lg py-1 px-2 font-bold"
          >
            <div className="flex">
              <div className="flex-1">{title}</div>
              <ChevronUpIcon className="-mr-1 ml-2 h-5 w-5 inline flex-none" aria-hidden="true" />
            </div>
            <div className="font-normal">{description}</div>
          </div>
        </div>
      }
    </div>
  )
}

export default SWOTItem;
