import React from 'react';
import { Link } from 'gatsby';

interface OtherLinkProps {
  imageSource: string
  link: string
  title: string
  description?: string
}

const OtherLink: React.FunctionComponent<OtherLinkProps> = ({ imageSource, link, title, description }) => (
  <Link to={link}>
    <div className="bg-dstl-light-gray overflow-hidden hover:bg-dstl-dark-gray p-4 my-2 mx-2 h-full">
      <div className="flex">
        <div className="flex-none">
          <img className="h-16 w-16" alt="Section" src={imageSource} />
        </div>
        <div className="flex-1 justify-start text-left px-4">
          <div className="flex-col justify-start text-left">
            <div className="text-lg font-bold mb-4">{title}</div>
            <div>{description}</div>
          </div>
        </div>
      </div>
    </div>
  </Link>
)

export default OtherLink